import { useContext, useEffect, useState } from "react";
import GalleryAsset from "../../common/GalleryAsset/GalleryAsset";
import HorizontalTabs from "../../common/HorizontalTabs/HorizontalTabs";
import { DesktopContext } from "../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { groupPhotosByGallery } from "../../primaryrender/editor/utils";
import { CheckIcon } from "@heroicons/react/solid";

export default function PhotoSelectModalGrid({
  isOpen,
  selectPhoto,
  renderWhiteCircles,
  selectedImages,
  photosType = "gallery",
  services = [],
}: any) {
  const { basicInfo, userPhoto, userGalleries, allDigitalMedia } =
    useContext(DesktopContext);
  const { second_last_login_time } = basicInfo || {};

  const [gallerySelected, setGallerySelected] = useState("All Photos");

  const [allPhotos, setAllPhotos] = useState({});
  const [galleries, setGalleries] = useState([]);

  const [displayedImages, setDisplayedImages] = useState<any[]>([]);

  useEffect(() => {
    setGallerySelected("All Photos");
  }, [isOpen]);

  useEffect(() => {
    getImages();
    getGalleries();
  }, []);

  function filterByGallery(gallery: string) {
    const filteredPhotos = allPhotos[gallery];
    setDisplayedImages(filteredPhotos);
    setGallerySelected(gallery);
  }

  function getImages() {
    if (photosType === "service") {
      let idCounter = 0;
      const photos = services.reduce((acc: any, service: any) => {
        acc[service["service-name"]] = service["photos"].map((photo: any) => ({
          id: idCounter++,
          data: photo["photo"],
          service: service["service-name"],
        }));
        return acc;
      }, {});
      const galleryNames = Object.keys(photos).map((key) => ({
        "gallery-name": key,
      }));
      setGalleries(galleryNames);
      setAllPhotos(photos);

      const firstKey = Object.keys(photos)[0];
      setDisplayedImages(photos[firstKey]);
      setGallerySelected(firstKey);
      return;
    } else if (photosType === "digital_assets") {
      const photos = allDigitalMedia?.map((photo: any) => ({
        id: photo.id,
        data:
          "https://landing-page-app-hero-images.s3.amazonaws.com/media/" +
          photo.s3_url,
        type: photosType,
      }));
      const galleryNames = [{ "gallery-name": "Digital Assets" }];
      setGalleries(galleryNames);
      setAllPhotos({ "All Photos": photos });
      setDisplayedImages(photos);
      return;
    }

    const photos = userPhoto;
    const current_galleries = userGalleries?.message?.galleries;

    const formattedImagesByGallery = groupPhotosByGallery(
      photos,
      current_galleries,
      second_last_login_time
    );
    setAllPhotos(formattedImagesByGallery);

    setDisplayedImages(formattedImagesByGallery["All Photos"]);
  }

  async function getGalleries() {
    if (
      !userGalleries ||
      photosType == "service" ||
      photosType == "digital_assets"
    )
      return;

    const allPhotosGallery = {
      "gallery-name": "All Photos",
      "gallery-subtitle": "",
    } as any;

    if (galleries && galleries.length === 0) {
      setGalleries([allPhotosGallery, ...userGalleries.message.galleries]);
    }
  }

  const galleryTabs = galleries.map((gallery) => {
    return {
      name: gallery["gallery-name"],
      selected: gallery["gallery-name"] === gallerySelected,
    };
  });

  return (
    <>
      <div className="sticky top-0 w-full overflow-x-auto no-scrollbar mb-4">
        <HorizontalTabs tabs={galleryTabs} onClick={filterByGallery} />
      </div>

      <div className="h-full sm:h-96 overflow-auto pb-4">
        <div className="grid grid-cols-2 sm:grid-cols-5 gap-1.5">
          {displayedImages?.map((image: any) => {
            const selected = selectedImages.some((i) => i.id === image.id);

            return (
              <div
                key={image.id}
                onClick={() => selectPhoto(image)}
                className="relative w-full h-28 cursor-pointer"
              >
                <GalleryAsset
                  media={image}
                  className="object-cover w-full h-full"
                />

                {renderWhiteCircles && (
                  <div
                    className={`absolute bottom-2 right-2 w-5 h-5 rounded-full border-2 border-white shadow-lg justify-center items-center ${
                      selected && "bg-blue-600"
                    }`}
                  >
                    {selected && (
                      <CheckIcon className="w-3 h-full m-auto text-white" />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
